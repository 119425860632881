import { mapState } from "vuex";
import { ANNOUNCE_LIST_ACTION } from "@/store/modules/announce/action";
import ListPageMixin from "@/mixins/ListPageMixin";

const AnnounceListMixin = {
  mixins: [ListPageMixin],
  data() {
    return {
      API_PATH: this.$apiPath.ANNOUNCE,
    };
  },
  beforeMount() {
    const params = this.checkUrlQuery();

    this.$store.dispatch(ANNOUNCE_LIST_ACTION).then(() => {
      this.getDataInit(params);
    });
  },
  computed: {
    ...mapState({
      statusList: (state) => state.announce.statusList,
    }),
  },
  methods: {
    // 주소 기준으로 현재 페이지에 필요한 params 가져온다.
    // 주소 기준으로 필터바 초기값을 셋팅한다.
    checkUrlQuery() {
      const { query } = this.$route;

      const objPaging = this.checkQueryPaging(query);

      const objKeyword = this.checkQueryKeywords(query, this.keywordList);

      const objDates = this.checkQueryDates(query, "regDate1", "regDate2");

      const objStatus = this.checkQueryStatus(query);

      const objCateNum = this.checkQueryCateNum(query);

      return Object.assign({}, objPaging, objKeyword, objDates, objStatus, objCateNum);
    },
    getFilterbarParams(objData) {
      const {
        keywordSelectedId,
        keywordInputText,
        startDt,
        endDt,
        status,
        category0,
        category1,
        category2,
      } = objData;

      let params = {};

      if (keywordSelectedId && keywordInputText) {
        params[keywordSelectedId] = keywordInputText;
      }

      if (startDt && endDt) {
        params["regDate1"] = startDt;
        params["regDate2"] = endDt;
      }

      if (status) {
        params.status = status;
      }

      const cateNum = category2 || category1 || category0;

      if (cateNum) {
        params.cateNum = cateNum;
      }

      return params;
    },
  },
};
export default AnnounceListMixin;
