<template>
  <PageWithLayout>
    <div class="section_comm">
      <div class="head_section">
        <h2 id="kakaoBody" class="tit_section">공고 현황</h2>
      </div>
      <div class="body_section">
        <AnnounceListFilterBar
          :keywordDatas="keywordList"
          :defaultKeywordSelectedId="defaultKeywordSelectedId"
          :defaultKeywordInputText="defaultKeywordInputText"
          :statusList="statusList"
          :defaultStateId="defaultStateId"
          :defaultDates="defaultDates"
          @onClickSearch="onClickSearch"
        />
        <h3 class="screen_out">공고 현황 목록</h3>
        <TableHead>
          <template v-slot:headRight>
            <SelectBoxPageRange
              :currentMaxRowCount.sync="size"
              @update:currentMaxRowCount="onChangeRowCount"
            />
          </template>
        </TableHead>
        <ListPage
          :list="dataList"
          :currentPageIndex="currentPageIndex"
          :totalPageCount="totalPageCount"
          :maxRowCount="size"
          :maxPaginationCount="maxPaginationCount"
          @onChangePage="onChangePage"
        >
          <template v-slot:colgroup>
            <col style="width: 121px" />
            <!-- 진행상태 -->
            <col style="width: 89px" />
            <!-- 등록일 -->
            <col style="width: 136px" />
            <!-- 공고 번호 -->
            <col style="width: 192px" />
            <!-- 공고 명 -->
            <col style="width: 124px" />
            <!-- 분류 -->
            <col style="width: 100px" />
            <!-- 구매 품목 -->
            <col style="width: 89px" />
            <!-- 입찰 시작일 -->
            <col style="width: 89px" />
            <!-- 입찰 마감일 -->
            <col style="width: 138px" />
            <!-- 발주 요청 업체 -->
            <col style="width: 162px" />
            <!-- 구매 담당자 -->
          </template>
          <template v-slot:tr>
            <th>진행 상태</th>
            <th>공고 등록일</th>
            <th>공고 번호</th>
            <th>입찰 공고명</th>
            <th>분류</th>
            <th>구매 품목</th>
            <th>입찰 시작일</th>
            <th>입찰 마감일</th>
            <th>발주 요청 업체</th>
            <th>구매 담당자</th>
          </template>
          <template v-slot:row="slotProps">
            <AnnounceListLine :rowData="slotProps.rowData" :statusList="statusList" />
          </template>
          <template v-slot:emptyList>
            <tr>
              <td colspan="10" class="td_empty">공고가 없습니다</td>
            </tr>
          </template>
        </ListPage>
      </div>
    </div>
  </PageWithLayout>
</template>

<script>
import PageWithLayout from "@/components/layout/PageWithLayout";
import AnnounceListFilterBar from "@/components/shared/announce/list/AnnounceListFilterBar";
import TableHead from "@/components/shared/TableHead";
import SelectBoxPageRange from "@/components/common/selectBox/SelectBoxPageRange";
import ListPage from "@/components/shared/ListPage";
import AnnounceListLine from "@/components/user/announce/list/AnnounceListLine";

import PageMixin from "@/mixins/PageMixin";
import AnnounceListMixin from "@/mixins/announce/list/AnnounceListMixin";

export default {
  name: "AnnounceList",
  components: {
    PageWithLayout,
    AnnounceListFilterBar,
    TableHead,
    ListPage,
    SelectBoxPageRange,
    AnnounceListLine,
  },
  mixins: [PageMixin, AnnounceListMixin],
  data() {
    return {
      keywordList: [
        { id: "title", text: "입찰 공고명" },
        { id: "postCid", text: "입찰 공고번호" },
      ],
    };
  },
};
</script>
