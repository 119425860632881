<template>
  <ListRow>
    <td>
      <span :class="['badge_state', stateType]">{{ rowData.statusName }}</span>
    </td>
    <td>{{ regDate }}</td>
    <td>{{ rowData.postCid }}</td>
    <td class="td_ellip" :data-ellip="rowData.title">
      <router-link :to="toPath" class="link_subject" :data-ellip="rowData.title">
        {{ rowData.title }}
      </router-link>
    </td>
    <td class="td_ellip align_left" :data-ellip="category">
      {{ category }}
    </td>
    <td class="td_ellip align_left" :data-ellip="rowData.itemName">
      {{ rowData.itemName }}
    </td>
    <td>{{ startDate }}</td>
    <td>{{ endDate }}</td>
    <td class="td_ellip" :data-ellip="rowData.companyName">
      {{ rowData.companyName }}
    </td>
    <td>{{ rowData.name }}</td>
  </ListRow>
</template>

<script>
import ListRow from "@/components/shared/ListRow";
import ListLineMixin from "@/mixins/ListLineMixin";
import { makePathWithQuery } from "@/utils/urlUtils";

export default {
  name: "AnnounceListLine",
  components: {
    ListRow,
  },
  mixins: [ListLineMixin],
  props: {
    statusList: Array,
  },
  computed: {
    toPath() {
      const { postCid } = this.rowData;
      const path = makePathWithQuery(this.$routerPath.ANNOUNCE_VIEW, postCid, this.$route.query);
      return path;
    },
    stateType() {
      switch (this.rowData.statusName) {
        case "입찰 진행 중":
          return "on";
        case "임시 저장":
          return "";
        case "공고 게시":
          return "";
        case "공고 마감":
          return "disabled";
      }
      return "";
    },
  },
};
</script>
